.toast-main {
  z-index: 11;
  width: 100%;
  position: absolute;
  left: 0;
}

.toast-container {
  position: relative;
  width: 80%;
}

.errorContainer {
  display: flex;
  background-color: red;
  color: rgb(255, 255, 255);
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 500;
  padding: 1rem 5rem;
  padding-right: 1rem;
  margin-bottom: 1rem;
  max-width: 100%;
}

.success-container {
  position: absolute;
  left: 0;
  top: 2px;
  display: flex;
  background-color: rgb(27, 167, 27);
  color: rgb(255, 255, 255);
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 500;
  padding: 1rem 5rem;
  padding-right: 1rem;
  width: 100%;
}

.errorCross {
  cursor: pointer;
}